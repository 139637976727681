.grid-container {
    display: grid;
}

.grid-row-1 {
    grid-row: span 1;
}

.grid-row-2 {
    grid-row: span 2;
}

.grid-row-3 {
    grid-row: span 3;
}

.grid-row-4 {
    grid-row: span 4;
}

.grid-span-column-1 {
    grid-column: span 1;
}

.grid-span-column-2 {
    grid-column: span 2;
}

.grid-span-column-3 {
    grid-column: span 3;
}

.grid-span-column-4 {
    grid-column: span 4;
}

.grid-column-2 {
    grid-column: 2;
}

.grid-gap-24 {
    grid-gap: 24px;
}

$columns: (4, 3);

@each $value in $columns {
    .grid-#{$value}-columns {
        grid-template-columns: repeat($value, 1fr);
    }
}

$rows: (4, 3);

@each $value in $rows {
    .grid-#{$value}-rows {
        grid-template-rows: repeat($value, 1fr);
    }
}
