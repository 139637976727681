@import 'app/shared/styles/colors';
@import 'app/shared/styles/variables';

button,
.p-button {
    cursor: pointer;
    border: none;

    &.large,
    &.small,
    &.medium {
        padding: 0 16px;
        font-weight: $font-weight-bold;
        border-radius: 3px;
        &:not(.icon-only):not(.ghost) {
            min-width: 100px;
        }

        @media (max-width: 767px) {
            padding: 0 12px;
            &:not(.icon-only):not(.ghost) {
                min-width: 0;
            }
        }
    }

    &.large {
        font-size: $font-size-lg;
        height: 48px;

        &.icon-only {
            width: 48px;
        }
    }

    &.medium {
        font-size: $font-size-md;
        height: 40px;

        &.icon-only {
            width: 40px;
        }
    }

    &.small {
        font-size: $font-size-sm;
        height: 32px;

        &.icon-only {
            width: 32px;
        }

        .p-button-icon {
            font-size: $font-size-xs;
        }
    }

    @media (max-width: 767px) {
        &.small {
            font-size: $font-size-xs;
        }
    }

    &.outlined {
        background-color: transparent;
        border: 1.5px solid $color-primary-300;
        color: $color-primary-300;

        &:hover,
        &:focus {
            background-color: $color-neutral-100;
        }

        &.red {
            color: $color-error-300;
            border-color: $color-error-300;
        }
    }

    &.filled {
        background-color: $color-primary-300;
        color: $color-neutral-white;

        &:hover,
        &:focus {
            background-color: $color-primary-400;
        }

        &:active {
            background-color: $color-primary-500;
        }

        &:disabled {
            opacity: 0.3;
        }

        &.red {
            background-color: $color-error-300;
        }
    }

    &.ghost {
        background-color: transparent;
        color: $color-primary-300;
        border: none;
        padding: 0;

        &:hover {
            background-color: transparent;
            color: $color-primary-400;

            .p-button-label,
            span:not(.p-button-icon) {
                text-decoration-line: underline;
            }
        }

        &:active {
            color: $color-primary-500;
        }

        &:disabled {
            color: $color-primary-300;
            opacity: 0.3;
        }

        &:focus {
            background-color: $color-neutral-100;
            color: $color-primary-300;
        }

        &.gray {
            color: $color-neutral-400;
        }
    }
}

.button-icon {
    &.green {
        background: $color-primary-100;
        color: $color-primary-300;
        border: 2px solid $color-primary-100;
    }

    &.white {
        background-color: $color-background-white;
        color: $color-primary-300;
        border: 2px solid $color-background-white;
    }

    padding: 8px;
    border-radius: $default-radius;
}

.circle-color-info {
    width: 12px;
    height: 12px;
    border-radius: 50%;

    $color: (
        background-1: $color-secondary-200,
        background-2: $color-secondary-300,
        background-3: $color-secondary-400,
        background-4: $color-secondary-500,
        background-5: $color-success-300,
        background-6: $color-success-500,
        background-7: $color-error-300,
    );

    @each $variation, $value in $color {
        &.color-#{$variation} {
            background-color: $value;
        }
    }
}

.text-align-center {
    text-align: center;
}

.field-set {
    > span {
        display: flex;
        align-items: center;
        height: 24px;
    }
}

.height-100 {
    height: 100vh;
}

.height-50 {
    height: 50vh;
}

.bordered-box {
    margin-top: 24px;
    position: relative;
    padding: 40px 24px 24px;
    border-radius: 8px;
    border: 1px solid $color-neutral-100;
    background-color: $color-background-white;
    flex: 1;

    .bordered-box-title {
        position: absolute;
        left: 15px;
        top: -19px;
        border-radius: 8px;
        border: 1px solid $color-neutral-100;
        background: $color-background-gray;
        padding: 10px;
        color: $color-neutral-400;
        font-size: $font-size-sm;
        font-weight: $font-weight-regular;
    }

    .bordered-box-content {
        border-radius: 10px;
        background: $color-neutral-100;
        padding: 16px;
    }
}

.dialog-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-background-white;
    padding: 24px;
    z-index: 2;
    border-radius: 0 0 8px 8px;

    .action-buttons {
        display: flex;
        gap: 16px;
        flex-direction: row-reverse;
    }
}

small {
    padding: 0 12px;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
}

.break-text-content {
    overflow-wrap: break-word;
}

.margin-right-2px {
    margin-right: 2px;
}

.height-40 {
    height: 40vh;
}

.min-height-40 {
    min-height: 40vh;
}

.min-width-27 {
    min-width: 27%;
}

.width-66 {
    width: 66%;
}

.width-50 {
    width: 50%;
}

.width-100 {
    width: 100%;
}

.no-margin {
    margin: 0;
}

.margin-1 {
    margin: 1rem;
}

.margin-2 {
    margin: 2rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.width-70vw {
    width: 70vw;
}

.height-fit-content {
    height: fit-content;
}

.validation-message {
    position: relative;
    color: $color-error-400;
    font-family: $font-family-roboto;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    padding: 0 12px;
}

.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.info-icon {
    font-size: inherit;
    padding: 0 4px;
    height: fit-content;
}
