$font-family-roboto: 'Roboto', sans-serif;
$font-family-vale: 'Vale Sans';

$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-regular: 400;

$default-line-height: 120%;

$font-size-xxxxl: 48px;
$font-size-xxxl: 40px;
$font-size-xxl: 32px;
$font-size-xl: 24px;
$font-size-lg: 20px;
$font-size-md: 16px;
$font-size-sm: 14px;
$font-size-xs: 12px;

$default-radius: 8px;
$default-spacing: 18px;

$icon-size: 16px;

$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
