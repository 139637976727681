@import 'app/shared/styles/variables';

h1 {
    font-family: $font-family-vale;
    font-size: $font-size-xxxxl;
    font-weight: $font-weight-semibold;
    line-height: $default-line-height;
}

h2 {
    font-family: $font-family-vale;
    font-size: $font-size-xxxl;
    font-weight: $font-weight-semibold;
    line-height: $default-line-height;
}

h3 {
    font-family: $font-family-vale;
    font-size: $font-size-xxl;
    font-weight: $font-weight-semibold;
    line-height: $default-line-height;
}

h4 {
    font-family: $font-family-vale;
    font-size: $font-size-xl;
    font-weight: $font-weight-semibold;
    line-height: $default-line-height;
}

h5 {
    font-family: $font-family-vale;
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
    line-height: $default-line-height;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

.font-body-lg-regular {
    font-family: $font-family-roboto;
    font-size: $font-size-lg;
    font-weight: $font-weight-regular;
    line-height: $default-line-height;
}

.font-body-lg-semibold {
    font-family: $font-family-roboto;
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
    line-height: $default-line-height;
}

.font-body-md-regular {
    font-family: $font-family-roboto;
    font-size: $font-size-md;
    font-weight: $font-weight-regular;
    line-height: $default-line-height;
}

.font-body-md-semibold {
    font-family: $font-family-roboto;
    font-size: $font-size-md;
    font-weight: $font-weight-semibold;
    line-height: $default-line-height;
}

.font-body-sm-regular {
    font-family: $font-family-roboto;
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;
    line-height: $default-line-height;
}

.font-body-sm-semibold {
    font-family: $font-family-roboto;
    font-size: $font-size-sm;
    font-weight: $font-weight-semibold;
    line-height: $default-line-height;
}

.font-body-xs-regular {
    font-family: $font-family-roboto;
    font-size: $font-size-xs;
    font-weight: $font-weight-regular;
    line-height: $default-line-height;
}

.font-body-xs-semibold {
    font-family: $font-family-roboto;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    line-height: $default-line-height;
}

.font-label-lg {
    font-family: $font-family-roboto;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    line-height: $default-line-height;
}

.font-label-md {
    font-family: $font-family-roboto;
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    line-height: $default-line-height;
}

.font-label-sm {
    font-family: $font-family-roboto;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    line-height: $default-line-height;
}
