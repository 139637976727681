.flex-container {
    display: flex;
}

.inline-flex-container {
    display: inline-flex;
}

.align-center {
    align-items: center;
    justify-content: center;
}

.justify-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.justify-left {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.align-top {
    align-items: flex-start;
}

.align-items-end {
    align-items: flex-end;
}

.align-self-center {
    align-self: center;
}

.justify-self-center {
    justify-self: center;
}

.flex-column {
    flex-direction: column;
}

.flex-stretch {
    align-items: stretch;
}

.flex-wrap {
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.flex-item {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-1-5 {
    flex: 1.5;
}

$gaps: (
    4: 4px,
    8: 8px,
    10: 10px,
    12: 12px,
    14: 14px,
    16: 16px,
    24: 24px,
    32: 32px,
    60: 60px,
    74: 74px,
    80: 80px,
);

@each $size, $value in $gaps {
    .gap-#{$size} {
        gap: $value;
    }
}
