@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import 'app/shared/styles/patterns';
@import 'app/shared/styles/variables';
@import 'app/shared/styles/colors';
@import 'app/shared/styles/typography';
@import 'app/shared/styles/grid-layout';
@import 'app/shared/styles/flexbox';
@import 'app/shared/styles/global';
@import 'app/shared/styles/colors-class';

html,
body {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;

    .p-component,
    .p-component * {
        &:not(.pi, .fa, .material-icons, [class^='dx-'], h1, h2, h3, h4, h5, h6) {
            font-family: $font-family-roboto;
            line-height: $default-line-height;
        }
    }
}

*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

*::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 7px;
    &:hover {
        background: $color-neutral-100;
    }
}

*::-webkit-scrollbar-thumb {
    background-color: $color-primary-300;
    border-radius: 7px;

    &:hover {
        background: $color-primary-400;
    }

    &:active {
        background: $color-primary-500;
    }
}

.actions-box {
    position: fixed;
    z-index: 200;
    bottom: 20px;
    right: 30px;

    > button {
        margin-left: 10px;
    }
}

.width-100 {
    width: 100%;
}

.radio-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;

    > .p-element {
        padding-right: 2.5px;
    }

    > label {
        cursor: pointer;
    }
}

@font-face {
    font-family: 'Vale Sans';
    src: url('/assets/fonts/ValeSans-Regular.ttf') format('truetype');
}

.app {
    display: grid;
    grid-template-areas:
        'sidebar header'
        'sidebar content';
    grid-template-columns: 6rem calc(100vw - 96px);
    grid-template-rows: auto 1fr;
    height: 100vh;
    overflow: hidden;
}

.app-header {
    grid-area: header;
}

.app-sidebar {
    grid-area: sidebar;
}

.app-page {
    grid-area: content;
    background-color: $color-background-gray;
    > * {
        overflow-y: auto;
    }
}

@media (max-width: 767px) {
    .app {
        grid-template-areas:
            'header'
            'content';
        grid-template-columns: 1fr;
    }

    .app-sidebar {
        display: none;
    }
}

// TO DO: Remover quando o DevExpress for removido do projeto
.dx-theme-generic-typography a {
    color: $gray1 !important;
}

.cdk-overlay-container {
    z-index: 99999;
}
