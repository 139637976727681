@import 'app/shared/styles/colors';

$colors: (
    primary: (
        500: $color-primary-500,
        400: $color-primary-400,
        300: $color-primary-300,
        200: $color-primary-200,
        100: $color-primary-100,
    ),
    secondary: (
        500: $color-secondary-500,
        400: $color-secondary-400,
        300: $color-secondary-300,
        200: $color-secondary-200,
        100: $color-secondary-100,
    ),
    tertiary: (
        500: $color-tertiary-500,
        400: $color-tertiary-400,
        300: $color-tertiary-300,
        200: $color-tertiary-200,
        100: $color-tertiary-100,
    ),
    success: (
        500: $color-success-500,
        400: $color-success-400,
        300: $color-success-300,
        200: $color-success-200,
        100: $color-success-100,
    ),
    warning: (
        500: $color-warning-500,
        400: $color-warning-400,
        300: $color-warning-300,
        200: $color-warning-200,
        100: $color-warning-100,
    ),
    error: (
        500: $color-error-500,
        400: $color-error-400,
        300: $color-error-300,
        200: $color-error-200,
        100: $color-error-100,
    ),
    info: (
        500: $color-info-500,
        400: $color-info-400,
        300: $color-info-300,
        200: $color-info-200,
        100: $color-info-100,
    ),
    neutral: (
        black: $color-neutral-black,
        white: $color-neutral-white,
        500: $color-neutral-500,
        400: $color-neutral-400,
        300: $color-neutral-300,
        200: $color-neutral-200,
        100: $color-neutral-100,
    ),
    background: (
        green: $color-background-green,
        white: $color-background-white,
        gray: $color-background-gray,
    ),
);

@each $category, $values in $colors {
    @each $key, $color in $values {
        .bg-color-#{$category}-#{$key} {
            background-color: #{$color};
        }

        .font-color-#{$category}-#{$key} {
            color: #{$color};
        }

        .border-color-#{$category}-#{$key} {
            border-color: #{$color};
        }
    }
}
