// TODO: apagar quando não estiver mais sendo utilizado em nenhum arquivo
$green0: #03605c;
$green1: #00807b;
$green2: #339995;
$green3: #85c2bf;
$green4: #cee7e5;
$green5: #f5fafa;

$gray0: #4a5362;
$gray1: #495057;
$gray2: #697489;
$gray3: #959dac;
$gray4: #b3b9c4;
$gray5: #d1d5db;
$gray6: #edeef0;
$gray7: #f4f4f5;

$red0: #bb133e;
$red2: #bb133e;
$red3: #c84264;
$red4: #d5718a;
$red6: #f0ced7;

$yellow0: #c68200;
$yellow1: #e99a17;
$yellow2: #ecb11f;
$yellow3: #efc04b;

$border-color: #e0e0e0;
$page-title-color: #828282;

// TODO: apagar acima quando não estiver mais sendo utilizado em nenhum arquivo

$color-primary-500: #073d3d;
$color-primary-400: #005858;
$color-primary-300: #007e7a;
$color-primary-200: #84d6d6;
$color-primary-100: #e6f2f2;

$color-secondary-500: #c18100;
$color-secondary-400: #e89a00;
$color-secondary-300: #ecb11f;
$color-secondary-200: #ffe07f;
$color-secondary-100: #fff4de;

$color-tertiary-500: #004462;
$color-tertiary-400: #006b8a;
$color-tertiary-300: #3cb5e5;
$color-tertiary-200: #a0e8ff;
$color-tertiary-100: #dcf7ff;

$color-success-500: #1e4500;
$color-success-400: #417917;
$color-success-300: #6cb535;
$color-success-200: #9ce366;
$color-success-100: #cafca5;

$color-warning-500: #c18100;
$color-warning-400: #e89a00;
$color-warning-300: #f0b600;
$color-warning-200: #ffe07f;
$color-warning-100: #fff4de;

$color-error-500: #540019;
$color-error-400: #9d002e;
$color-error-300: #bb133e;
$color-error-200: #fc6f6f;
$color-error-100: #ffd9d9;

$color-info-500: #0873a1;
$color-info-400: #0a9fde;
$color-info-300: #31bbf5;
$color-info-200: #8ad8f9;
$color-info-100: #b6e7fc;

$color-neutral-black: #161d1f;
$color-neutral-white: #ffffff;

$color-neutral-500: #333333;
$color-neutral-400: #5a5a5a;
$color-neutral-300: #9e9e9e;
$color-neutral-200: #d0d0d0;
$color-neutral-100: #ebebeb;

$color-background-green: #f2f8f8;
$color-background-white: #ffffff;
$color-background-gray: #f8f8f8;
